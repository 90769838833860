* {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}
html {
    font-size: 10px;
    -webkit-text-size-adjust: none;
    touch-action: manipulation;
    font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
}
body {
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    font-family: "franklin-gothic-urw", Helvetica, Arial, sans-serif;
    color: #5B5B5B;
}
button {
    font-family: "franklin-gothic-urw-cond";
}

body.react-confirm-alert-body-element {
    overflow: hidden;
}
.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}
.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}
.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}
.react-confirm-alert-body > h1 {
    margin-top: 0;
}
.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}
.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}
.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}
.react-confirm-alert {
    width: 90%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 12px 15px -4px rgba(0,0,0,0.1);
    border: 1px solid #f9f9f9;
}
.react-confirm-alert-overlay {
    backdrop-filter: blur(15px);
    background: #000000a3 !important;
}
.react-confirm-alert #jako-configurator-3d-wc .outlineButton {
    border-color: #008AC9;
    color: #008AC9;
    font-weight: 500;
}
.react-confirm-alert #jako-configurator-3d-wc .outlineButton:hover {
    color: #ffffff;
}

.confirm-popup-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 12px;
}
.confirm-popup__save-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}
.confirm-popup__save-container .save-popup__icon {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.25rem;
}
.confirm-popup__save-container .save-popup__title {
    font-weight: 600;
    font-size: 25px;
    color: #141414;
    margin-bottom: 1.25rem;
    line-height: 1;
    font-family: franklin-gothic-urw,Helvetica,Arial,sans-serif;
    margin-top: 0;
}
.confirm-popup__save-container p {
    color: #141414;
}
.confirm-popup__func-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
}
.confirm-popup__func-save-copy-container {
    display: flex;
    flex-direction: column;
}

.confirm-popup__refresh-func-container {
    display: flex;
    flex-direction: column;
}
.confirm-popup__refresh-func-container .outlineButton {
    border: 1px solid #008ac9;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 18px;
    padding: 9px 20px 11px;
    vertical-align: middle;
    color: #008ac9;
    font-weight: 500;
    margin-top: 10px;
}
.confirm-popup__refresh-func-container .outlineButton:hover {
    background: #008AC9;
    color: #ffffff;
    border-color: #008AC9;
}

.confirm-popup__func-container .outlineButton {
    border: 1px solid #008ac9;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 18px;
    padding: 9px 20px 11px;
    vertical-align: middle;
    color: #008ac9;
    font-weight: 500;
}
.confirm-popup__func-container .outlineButton:hover {
    background: #008AC9;
    color: #ffffff;
    border-color: #008AC9;
}
.confirm-popup__func-container .button_primary {
    margin-bottom: 1.25rem;
    background-color: #008ac9;
    border: 1px solid #008ac9;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: franklin-gothic-urw-cond,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    min-width: 100px;
    padding: 9px 20px 11px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
}
.confirm-popup-container .button_primary {
    background-color: #008ac9;
    border: 1px solid #008ac9;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: franklin-gothic-urw-cond,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    min-width: 100px;
    padding: 9px 20px 11px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
}
.confirm-popup-container form input {
    margin-bottom: 1rem;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #bfbfbf;
    border-radius: 0;
    box-shadow: none;
    display: block;
    font-size: 17px;
    line-height: 22px;
    padding: 7px 15px 9px;
    width: 100%;
    color: #5b5b5b;
    font-family: franklin-gothic-urw-cond,Helvetica,Arial,sans-serif;
}
.confirm-popup-container .confirm-popup-container__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: .75rem;
}
@media (min-width: 768px) {
    .confirm-popup__func-container {
        flex-direction: row;
    }
    .confirm-popup__func-container .button_primary {
        margin-bottom: 0;
    }
    .confirm-popup-container .confirm-popup-container__title {
        font-size: 2.25rem;
    }
    .confirm-popup__func-save-copy-container {
        flex-direction: row-reverse;
        gap: 14px;
    }
}
@media (min-width: 1024px) {
    .react-confirm-alert {
        width: 600px;
    }
    .react-confirm-alert-overlay {
        backdrop-filter: none;
        background: rgba(255, 255, 255, 0.9) !important;
    }
    .confirm-popup-container {
        padding: 0;
    }
}
@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@media (max-width: 1539px) {
    body {
      font-size: 14px;
    }
}
@media (max-width: 1539px) {
    body {
      font-size: 14px;
    }
}
@font-face {
    font-family: "icons";
    font-display: swap;
    src: url('./icons.woff2') format('woff2');
}
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf');
    font-weight: 400;
}

/* @font-face {
    font-family: 'Arial Black';
    src: url('../../../backend-node/fonts/ArialBlack.woff2');
}
@font-face {
    font-family: 'Bebas Neue';
    src: url('../../../backend-node/fonts/BebasNeue.woff2');
}
@font-face {
    font-family: 'CG Omega';
    src: url('../../../backend-node/fonts/CGOmega.woff2');
}
@font-face {
    font-family: 'Oswald Stencil';
    src: url('../../../backend-node/fonts/OswaldStencil.woff2');
}
@font-face {
    font-family: 'Swiss921 BT';
    src: url('../../../backend-node/fonts/Swiss921BT.woff2');
}
@font-face {
    font-family: 'Akhand Black';
    src: url('../../../backend-node/fonts/AkhandBlack.otf');
}
@font-face {
    font-family: 'American Captain';
    src: url('../../../backend-node/fonts/AmericanCaptain.ttf');
}
@font-face {
    font-family: 'Carbon Black';
    src: url('../../../backend-node/fonts/CarbonBlack.ttf');
}
@font-face {
    font-family: 'CruzAzul';
    src: url('../../../backend-node/fonts/CruzAzul.ttf');
}
@font-face {
    font-family: 'Komikazoom';
    src: url('../../../backend-node/fonts/Komikazoom.ttf');
}
@font-face {
    font-family: 'Norwester';
    src: url('../../../backend-node/fonts/Norwester.otf');
}
@font-face {
    font-family: 'TCCB';
    src: url('../../../backend-node/fonts/TCCB.ttf');
} */
@font-face {
    font-family: "franklin-gothic-urw";
    src: url("https://use.typekit.net/af/9cb78a/0000000000000000000118ad/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/9cb78a/0000000000000000000118ad/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/9cb78a/0000000000000000000118ad/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "franklin-gothic-urw";
    src: url("https://use.typekit.net/af/2e6f07/000000000000000000011ce6/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/2e6f07/000000000000000000011ce6/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/2e6f07/000000000000000000011ce6/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "franklin-gothic-urw";
    src: url("https://use.typekit.net/af/b825af/0000000000000000000118b1/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/b825af/0000000000000000000118b1/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/b825af/0000000000000000000118b1/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: "franklin-gothic-urw";
    src: url("https://use.typekit.net/af/c7b613/0000000000000000000118b3/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/c7b613/0000000000000000000118b3/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/c7b613/0000000000000000000118b3/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 900;
}
@font-face {
    font-family: "franklin-gothic-urw-cond";
    src: url("https://use.typekit.net/af/bb00d4/00000000000000003b9b2244/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/bb00d4/00000000000000003b9b2244/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/bb00d4/00000000000000003b9b2244/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}
/*@font-face {
  font-family:"franklin-gothic-urw-cond";
  src:url("https://use.typekit.net/af/a30f3c/00000000000000003b9b2245/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/a30f3c/00000000000000003b9b2245/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/a30f3c/00000000000000003b9b2245/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:500;
}*/
@font-face {
    font-family: "franklin-gothic-urw-cond";
    src: url("https://use.typekit.net/af/a2031c/0000000000000000000118b9/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/a2031c/0000000000000000000118b9/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/a2031c/0000000000000000000118b9/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}
